export default {
  Blog: '部落格組件',
  ButtonText: '按鈕與文字',
  Carousel: '輪播',
  Picture: '圖片',
  PictureText: '圖片與文字',
  Product: '商品',
  QuestionAnswer: '問與答',
  Text: '文字',
  Video: '影片',
  Table: '列表',
  Form: '表格',

  // color

  mainColor: "網頁主色",
  login:"登入頁顏色",
  profile: "個人頁面顏色",
  blog:"部落格顏色",
  productList:"商品列表顏色",
  product:"商品內頁顏色",


  mainColor1: "主顏色1",
  mainColor2: "主顏色2",
  mainColor3: "主顏色3",
  blockColor: "區塊",
  textColor: "文字",
  backgroundColor: "背景",
}
