import {defineStore} from 'pinia';
import {request} from "@/plugins/axios";
import {useLanguageStore} from "@/stores/language";
import {useDistributionStore} from "@/stores/distribution";

export const useSEOStore = defineStore("SEO", {
  state: () => ({
    //網頁標題
    seoTitle: null,
    //分頁名稱
    languageName: "新的分頁",
    keywords: null,
    seoIntroduction: null,
    url: null
  }),
  actions: {
    setSEOData(obj) {
      this.title = obj.title;
    },
    getPutData() {
      const seoStore = useSEOStore();
      const languageStore = useLanguageStore();



      return {
        keyword: seoStore.keywords,
        relationUrl: seoStore.url,
        languageData: Object.keys(languageStore.languageTag).map(lang => {
          const copy = languageStore.seoLanguage[languageStore.showLanguageId];
          const seo = languageStore.seoLanguage[lang];
          const page = languageStore.pageLanguage[lang];

          return {
            title: seo.seoTitle  ? seo.seoTitle : copy.seoTitle,
            introduction: seo.seoIntroduction ? seo.seoIntroduction : copy.seoIntroduction,
            pageLanguageId: page.pageLanguageId,
          }
        })
      }
    },
    async fetchUpdate() {
      const distributionStore = useDistributionStore();
      let data = this.getPutData();

      return request.put(`design/page/seo/${distributionStore.id}`, data).then( data => {
        alert('更新SEO成功');
        return data;
      }).catch(e => alert('更新SEO失敗：'+ e.message));
    }
  }
});
