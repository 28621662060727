<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    fetch('https://official-image.s3.ap-northeast-1.amazonaws.com/config/zh-TW.json').then(
        async data => {
          let json = await data.json();
          let old = this.$i18n.getLocaleMessage('zh-TW');

          this.$i18n.setLocaleMessage('zh-TW', {...json, ...old});
        }
    )
  }
}
</script>

<style></style>
