import {defineStore} from 'pinia';
import {request} from "@/plugins/axios";

export const initColor = {
  mainColor: {
    mainColor1: '#FFF',
    mainColor2: '#000',
    mainColor3: '#fafafa',
  },
  login: {
    blockColor: "#fafafa",
    backgroundColor: "#FFF",
    textColor: "#000",
  },
  profile: {
    blockColor: "#fafafa",
    backgroundColor: "#FFF",
    textColor: "#000",
  },
  blog: {
    blockColor: "#fafafa",
    backgroundColor: "#FFF",
    textColor: "#000",
  },
  productList: {
    blockColor: "#fafafa",
    backgroundColor: "#FFF",
    textColor: "#000",
  },
  product: {
    blockColor: "#fafafa",
    backgroundColor: "#FFF",
    textColor: "#000",
  },
  payment: {
    blockColor: "#fafafa",
    backgroundColor: "#FFF",
    textColor: "#000",
  },
  auto: {
    mainTextColor1: '#000',
    mainTextColor2: '#FFF',
  }
}


export const useColorStore = defineStore("color", {
  state: () => ({
    colorObject: {}
  }),
  getters: {
    getMainColor() {
      const {mainColor1, mainColor2, mainColor3} = this.colorObject.mainColor ? this.colorObject.mainColor : {};
      return {
        mainColor1 : mainColor1 ? mainColor1 : null,
        mainColor2 : mainColor2 ? mainColor2 : null,
        mainColor3 : mainColor3 ? mainColor3 : null,
      };
    }
  },
  actions: {
    /**
     * 設定顏色
     * @return {Promise<void>}
     */
    async postColorData(data) {
      let {result} = await request.put('design/setting/style', {
        styleObject: data
      });
      if (result) {
        this.colorObject = data;
      }
      return result;
    },
    setColorObject(styleObject) {
      let obj = {};
      // let colorSetList = ['mainColor', 'login', 'profile', 'blog', 'productList', 'product'];
      for (let k in initColor) {
        if (styleObject[k]) {
          obj[k] = {...initColor[k], ...styleObject[k]};
        } else {
          obj[k] = initColor[k];
        }
      }

      this.colorObject = obj;
    }
  }
});
