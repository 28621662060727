import {defineStore} from "pinia";
import {request} from "@/plugins/axios";
import {useSEOStore} from "@/stores/seo";
import {useLanguageStore} from "@/stores/language";
import {useDistributionStore} from "@/stores/distribution";

export const useFormStore = defineStore("form", {
  actions: {
    setPostData(dataLanguage, isCreate) {
      const seoStore = useSEOStore();
      const languageStore = useLanguageStore();

      return {
        name: seoStore.languageName,
        languageData: Object.keys(languageStore.languageTag).map(key => {
          const id = languageStore.languageTag[key];
          const page = languageStore.pageLanguage[key];
          const pageData = dataLanguage[key][0].children;
          const listData = {
            languageId: id,
            jsonString: JSON.stringify([pageData[1]]),
          }
          if (!isCreate) {
            listData.filePath = page.filePath
          }
          return listData;
        })
      }
    },
    async fetchData({id}) {
      const pageLanguage = {};
      const languageStore = useLanguageStore();
      const seoStore = useSEOStore();
      let {result} = await request.get(`/design/form/${id}`);
      let {languageData, name} = result;

      seoStore.languageName = name;

      for (let key in languageData) {
        const item = languageData[key];

        languageStore.pageLanguage[key] = {
          filePath: item.filePath,
          formLanguageId: item.formLanguageId
        }

        let container = await fetch(item.formJsonUrl)
          .then(async data => await data.json())
          .catch(() => []);

        if (!pageLanguage[key])
          pageLanguage[key] = languageStore.fetchLanguageData[key] ? languageStore.fetchLanguageData[key] : {};
        pageLanguage[key].container = container;
      }

      languageStore.fetchLanguageData = {...languageStore.fetchLanguageData, ...pageLanguage};
    },
    async fetchSave({id, data}) {
      const distributionStore = useDistributionStore();
      const isCreate = distributionStore.isCreate;
      const method = distributionStore.fetchMethod;

      let {result} = await request[method](`/design/form/${isCreate ? '' : id}`, this.setPostData(data, isCreate));
      // await this.fetchData({id: result.id || id});
      return result?.id || id;
    },
  }
});
