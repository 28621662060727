import axios from 'axios';
import {useDistributionStore} from "@/stores/distribution";

let distributionStore;
export const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  timeout: 20000
});

request.interceptors.request.use(
  config => {
    config.headers['x-access-token'] = distributionStore.token;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  response => {
    if (response.status === 200) {
      const {data} = response;
      return data;
    }
  },
  err => {

    // 一般的 response
    const {error} = err.response.data;
    if (error.message === 'jwt expired' || error.message === 'token error') {
      console.error('token error');
    }
    return Promise.reject(error);
  }
);

export default {
  install: (app) => {
    distributionStore = useDistributionStore();
    app.prototype.$axios = request;
  }
};


