export const feature = {
  save: 'save',
  publish: 'publish',
  preview: 'preview',
  seo: 'seo',
  rootColor: 'rootColor',
  header: 'header',
  footer: 'footer',
  layout: 'layout',
}

export default {
  page: {
    save: true,
    publish: true,
    preview: true,
    seo: true,
    rootColor: true,
    header: true,
    footer: true,
    layout: true,
  },
  form: {
    save: true,
    publish: false,
    preview: true,
    seo: false,
    rootColor: false,
    header: false,
    footer: false,
    layout: true,
  }
}
