import {defineStore} from "pinia";
import {request} from "@/plugins/axios";

export const useLanguageStore = defineStore("language", {
  state: () => ({
    seoLanguage: {},
    pageLanguage: {},


    fetchData: [],
    fetchLanguageData: {},
    showLanguageId: null,
    languageObj: {},
    languageIds: [],
    languageTag: {},
    languageInit: {},
  }),
  getters: {
    selectLanguage() {
      return this.fetchData.map(v => ({value: v.languageTag, text: v.fullName}));
    }
  },
  actions: {
    getLanguageData() {
      return this.fetchLanguageData[this.showLanguageId];
    },
    setLanguage(id, data) {
      for (let tag of this.languageIds) {
        this.languageObj[tag][id] = {...data}
      }
    },
    async fetchLanguageList() {
      const {result} = await request.get('/language');
      for (let item of result) {
        let id = item.languageTag;
        this.languageIds.push(id);
        this.languageInit[id] = false;
        this.languageTag[id] = item.languageId;
        this.languageObj[id] = {tag: id};
      }
      this.fetchData = result;
      this.showLanguageId = result[0] ? result[0].languageTag : null;
    },
    async fetchLanguageComponent() {
    },
    async putLanguageComponent(data) {
      for (let k in data) {
        localStorage.setItem(k, JSON.stringify(data[k]));
      }
    }
  }
});
