import {defineStore} from 'pinia';
import {request} from "@/plugins/axios";
import {useLanguageStore} from "@/stores/language";
import {useSEOStore} from "@/stores/seo";
import {useDistributionStore} from "@/stores/distribution";


//處理送出資料
function setPostData(dataLanguage, isCreate) {
  const languageStore = useLanguageStore();

  return {
    type: "page",
    languageData: Object.keys(languageStore.languageTag).map(key => {
      const copySEO = languageStore.seoLanguage[languageStore.showLanguageId];
      const id = languageStore.languageTag[key];
      const seo = languageStore.seoLanguage[key];
      const page = languageStore.pageLanguage[key];
      const pageData = dataLanguage[key][0].children;

      pageData[0].name = pageData[0].components;
      pageData[2].name = pageData[2].components;

      const listData = {
        languageId: id,
        languageName: seo.languageName || copySEO.languageName,
        componentModuleJsonString: JSON.stringify([pageData[1]]),
        headerAndFooterJsonString: {
          header: pageData[0].children.length > 0 ? pageData[0] : null,
          footer: pageData[2].children.length > 0 ? pageData[2] : null,
        }
      }
      if (!isCreate) {
        listData.pageLanguageId = page.pageLanguageId
        listData.filePath = page.filePath
      }
      return listData;
    })
  }
}


export const usePageStore = defineStore("page", {
  actions: {

    async fetchData({id}) {
      const pageLanguage = {};
      const seoStore = useSEOStore();
      const languageStore = useLanguageStore();
      let {result} = await request.get(`/design/page/component/json/${id}`);
      let {languageData, seoKeyword, seoRelationUrlName} = result;

      // 設定SEO不是多國語系的資料
      seoStore.keywords = seoKeyword;
      seoStore.url = seoRelationUrlName;

      for (let key in languageData) {
        const item = languageData[key];
        let container;

        languageStore.pageLanguage[key] = {
          filePath: item.filePath,
          pageLanguageId: item.pageLanguageId
        }

        if (languageStore.seoLanguage[key]) languageStore.seoLanguage[key] = {}
        languageStore.seoLanguage[key] = {
          seoTitle: item.seoTitle,
          languageName: item.languageName,
          seoIntroduction: item.seoIntroduction,
        }

        container = await fetch(item.componentModuleJsonUrl)
          .then(async data => await data.json())
          .catch(() => []);

        if (!pageLanguage[key])
          pageLanguage[key] = languageStore.fetchLanguageData[key] ? languageStore.fetchLanguageData[key] : {};
        pageLanguage[key].container = container;
      }

      languageStore.fetchLanguageData = {...languageStore.fetchLanguageData, ...pageLanguage};
    },
    async fetchSave({id, data}) {
      const distributionStore = useDistributionStore();
      const isCreate = distributionStore.isCreate;
      const method = distributionStore.fetchMethod;
      let {result} = await request[method](`/design/page/save_draft/${isCreate ? 'json' : id}`, setPostData(data, isCreate));
      // await this.fetchData({id: result?.pageId || id});
      return result?.pageId || id;
    },
    async fetchPublish({id, data}) {
      const distributionStore = useDistributionStore();
      const isCreate = distributionStore.isCreate;
      const method = distributionStore.fetchMethod;


      let {result} = await request[method](`/design/page/publish_save/${isCreate ? 'json' : id}`, setPostData(data, isCreate));

      return result?.pageId || id;
    },
  }
});
