import Vue from 'vue';
import { createPinia, PiniaVuePlugin } from 'pinia'
import App from '@/App.vue';
import router from '@/router';
import axios from '@/plugins/axios.js';
import i18nData from '@/i18n';
import components from '@/plugins/components.js';
import { datadogLogs } from '@datadog/browser-logs';
import VueI18n from 'vue-i18n';
import '@/assets/css/index.css';
import '@shopinn/components/dist/index.css';
import '@/assets/css/fontawesome.min.css'
import '@/assets/css/solid.min.css';
import '@/assets/css/regular.min.css';

datadogLogs.init({
  clientToken: 'pub97f684b8c16ed2bf1a6b4d24def7bb2d',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sampleRate: 100,
  env: "dev2"
});

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = event => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'zh-TW',
  fallbackLocale: 'zh-TW',
  messages: i18nData
})


Vue.use(components, {});
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
datadogLogs.logger.info('init logger', {name: 'alannnnnn', id: 123 });
Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  pinia,
  render: h => h(App),
}).$mount('#app')
Vue.use(axios, {});




