import {installComponents, componentConfig, installLayouts} from "@shopinn/components";

export default {
  install: async function (Vue) {

    // 需要等到 createPinia 才能夠載入 Store
    const {useComponentSettingStore} = await import("@/stores/componentSetting");
    const store = useComponentSettingStore();

    for (let key in componentConfig) {
      let obj = componentConfig[key];
      switch (obj.type) {
        case 'header':
          store.header[key] = obj;
          break;
        case 'footer':
          store.footer[key] = obj;
          break;
        case 'container':
        default:
          store.container[key] = obj;
      }
    }
    store.allSetting = componentConfig;
    installComponents(Vue);
    installLayouts(Vue);
  }
};
