import {defineStore} from "pinia";
import editFeature from "@/model/editFeature";
import {useLanguageStore} from "@/stores/language";
import {useColorStore} from "@/stores/color";
import {request} from "@/plugins/axios";
import {usePageStore} from "@/stores/page";
import {useFormStore} from "@/stores/form";

export const useDistributionStore = defineStore("distribution", {
  state: () => ({
    id: null,
    ver: null,
    token: null,
    editType: 'page',
  }),
  getters: {
    isCreate: state => {
      return state.id == null
    },
    fetchMethod: state => state.isCreate ? 'post' : 'put',
  },
  actions: {
    canUse(name) {
      let featureObject = editFeature[this.editType] || {};
      return featureObject[name] || false;
    },
    async distributionMethod(id, methodName, data) {
      let result;

      if (this.isCreate && methodName === 'fetchData') return;
      switch (this.editType) {
        case 'page':
          result = await usePageStore()[methodName]({...data, id: id || this.id});
          break;
        case 'form':
          result = await useFormStore()[methodName]({...data, id: id || this.id});
          break;
        default:
          result = await usePageStore()[methodName]({...data, id: id || this.id});
      }

      return result;
    },
    async setContainerLang(id) {
      return await this.distributionMethod(id, 'fetchData');
    },
    async sendSave(data) {
      return await this.distributionMethod(null, 'fetchSave', {data});
    },
    async sendPublish(data) {
      return await this.distributionMethod(null, 'fetchPublish', {data});
    },
    async fetchGlobalData() {
      const languageStore = useLanguageStore();
      const colorStore = useColorStore();
      const pageLanguage = {};
      let {result} = await request.get('/design/page/global_object/json');
      let {languageData} = result;

      for (let key in languageData) {
        let global = await fetch(languageData[key].globalUrl)
          .then(async data => {
            return await data.json()
          }).catch(() => {
            return {
              header: null,
              footer: null,
              style: {},
            };
          });

        if (!pageLanguage[key])
          pageLanguage[key] = {};
        this.fbMessengerPageId = global.thirdPartData && global.thirdPartData.fbMessengerId ?
          global.thirdPartData.fbMessengerId : null;
        pageLanguage[key].header = global.header && global.header.children ? global.header : null;
        pageLanguage[key].footer = global.footer && global.footer.children ? global.footer : null;
        colorStore.setColorObject(global.style);
      }
      languageStore.fetchLanguageData = {...languageStore.fetchLanguageData, ...pageLanguage};
    },

  }
});
