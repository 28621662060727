import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('@/App.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/home-page.vue'),
      },
      {
        path: '/preview',
        name: 'preview',
        component: () => import('@/views/preview-page.vue'),
      },
      {
        path: '/test',
        name: 'test',
        component: () => import('@/views/test-layout.vue'),
      },
      {
        path: '/tip',
        name: 'tip',
        component: () => import('@/views/tip.vue'),
      },
    ]
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes // short for `routes: routes`
});

export default router;
